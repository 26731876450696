.upload {
  :global {
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      padding: 0 !important;
      border: 1px solid #ebeef5 !important;
      border-radius: 0 !important;


      &::before {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .ant-upload.ant-upload-select-picture-card {
      border: 1px solid #ebeef5 !important;
      border-radius: 0 !important;
    }

    .ant-upload.ant-upload-select-picture-card > .ant-upload {
      padding: 0 !important;
    }
  }
}

.photoSlider {
  :global {
    .PhotoView__Photo {
      width: auto !important;
      max-width: 800px;
      height: auto !important;
      max-height: 500px;
      object-fit: contain;
    }
  }
}

.mask {
  background-color: rgba(0,0,0,60%) !important;
}

@hack: true; @import "~@/assets/styles/color.less";