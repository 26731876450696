:global {
  .gm_link_button {
    border: none;

    &_disabled {
      background-color: initial;
    }

    &:disabled {
      color: rgba(70, 76, 91, 25%) !important;
    }

    &:hover span,
    &:active span {
      text-decoration: underline;
    }

    &_default {
      color: @gray-1;

      &:hover,
      &:focus {
        color: @gray-2;
      }

      &:active {
        color: #333;
      }
    }

    &_warning {
      color: @orange-1;

      &:hover,
      &:focus {
        color: @orange-hover;
      }

      &:active {
        color: @orange-active;
      }
    }

    &_danger {
      color: @red-1;

      &:hover,
      &:focus {
        color: @red-hover;
      }

      &:active {
        color: @red-active;
      }
    }
  }
}
@hack: true; @import "~@/assets/styles/color.less";