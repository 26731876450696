.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  background-color: #fff;

  &.left-border {
    border-left: 2px solid @primary-color;
  }

  &.bottom-border {
    border-bottom: 1px solid #ebeef5;
  }

  .gm-title {
    margin-left: 20px;
    color: @primary-color;
    font-weight: 700;
    font-size: 16px;
  }
}

.btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
}

.templateSearch:hover {
  background: #f5f5f5;
}

.down {
  height: 30px;
  padding: 5px 10px;
  line-height: 30px;
  border: 1px solid #eee;
  border-radius: 5px;
  cursor: pointer;
}

@hack: true; @import "~@/assets/styles/color.less";