.closeIcon {
  color: #9ea7b4;
  font-weight: bold;
  font-size: 18px;

  &:hover,
  &:active {
    color: #666;
  }
}


:global {
  .ant-modal-confirm .ant-modal-close {
    display: inline-block;
  }
}

@hack: true; @import "~@/assets/styles/color.less";