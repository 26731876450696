.ant-pro-layout {
  height: 100%;
}

.gm-content {
  box-sizing: border-box;
  padding: 20px;
  overflow: auto;
  height: 100%;
  background-color: #fff;
}

.gm-content--scroll {
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;

  &.child-is-tabs {
    height: 100vh;
  }
}

.gm-table {
  &.ant-table-wrapper .ant-table-tbody>.ant-table-row>td {
    padding: 0 8px !important;
    height: 48px;
  }

  &.ant-table-wrapper .ant-table-thead>tr>th {
    padding: 0 8px !important;
    height: 48px;
    color: #9ea7b4;
  }

  .ant-pagination-mini {

    .ant-pagination-prev,
    .ant-pagination-next {
      background-color: transparent;
      border-color: transparent;
    }
  }

  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next {
    border: 1px solid #DCDEE0;
  }

  .ant-pagination-item-link {
    >.anticon {
      vertical-align: initial;
    }
  }

  .ant-pagination-item-active {
    background-color: @primary-color;
    border-color: @primary-color;

    >a {
      color: #fff !important;
    }
  }
}

.gm-tab {
  width: 100%;

  >.ant-tabs-nav {
    .ant-tabs-tab {
      border-radius: 0 !important;
    }

    .ant-tabs-tab+.ant-tabs-tab {
      border-left-width: 0 !important;
    }

    .ant-tabs-tab-active {
      font-weight: 500;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
        height: 2px;
        background-color: @primary-color !important;
      }
    }
  }
}

.gm-tab-cachet {
  .ant-tabs-ink-bar {
    display: none !important;
  }

  .ant-tabs-tab {
    margin: 0 !important;
    height: 32px !important;
    padding: 6px 10px !important;
    color: @primary-color;
    text-align: center;
    box-sizing: border-box;
    border: 1px solid @primary-color;

    &:hover {
      color: @primary-color;
    }

  }


  .ant-tabs-tab+.ant-tabs-tab {
    border-left-width: 0 !important;
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #fff !important;
    }

    background-color: @primary-color;
  }

  .ant-tabs-nav-operations {
    height: 32px;
  }

  .ant-tabs-nav::before {
    border-bottom: none
  }
}

.gm-tab-pane {
  margin-top: 20px;
}

.ant-list .ant-list-item {
  padding: 0 !important;
}

.gm-modal {
  .ant-modal-header {
    padding: 12px 24px;
  }

  .ant-modal-body {
    padding: 16px 24px;
    min-height: 80px;
    overflow-y: auto;
  }
}

.ant-modal-confirm {
  .ant-modal-body {
    padding: 24px 24px 16px;
  }

}

.gm-drawer {
  .ant-drawer-header {
    background-color: rgb(245, 247, 249);
  }
}

.gm-drawer-page {
  .ant-drawer-header {
    border-bottom: none !important;
    padding: 0 !important
  }
}


.gm-timeline-adapter-loading {
  width: 100%;
  padding: 32px 0;
  text-align: center;
}

.gm-timeline {
  @space: 112px;

  .ant-timeline-item-label {
    width: 100px !important;
    inset-block-start: -4px !important;
  }

  .ant-timeline-item-head-custom,
  .ant-timeline-item-tail {
    inset-inline-start: @space !important;
  }

  .ant-timeline-item-content {
    inset-inline-start: @space - 12px !important;
    inset-block-start: 0 !important;
    width: calc(100% - @space - 12px - 4px) !important;
  }

  .gm-timeline-dot {
    width: 12px;
    height: 12px;
  }
}

.gm-checkbox-group-v {
  display: block;
}

.ant-modal-confirm-content {
  margin-block-start: 16px !important;
  min-height: 80px;
}
