:global {
  .gm__notification {
    position: relative;
    width: 320px !important;
    padding: 0 !important;
    border-radius: 4px !important;
    box-shadow: rgba(0,0,0,35%) 0 0 12px 0 !important;

    .ant-notification-notice-message {
      margin-bottom: 0 !important;
    }

    .ant-notification-notice-close {
      top: 10px !important;
      right: 10px !important;
    }

    &__close-icon {
      display: inline-block;
      //background-color: red;
      svg path {
        fill: #ccc;
      }

      svg:hover path,
      svg:active path {
        fill: #1abc9c;
      }
    }

    &__container {
      display: flex;
      //align-items: center;
      padding: 15px 20px;
    }

    &__icon {
      margin-right: 14px;
      font-size: 18px;

      &--success {
        color: @primary-color;
      }

      &--info {
        color: #39f;
      }

      &--warning {
        color: @orange-1;
      }

      &--error {
        color: @red-1;
      }
    }

    &__prefix {
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 100%;
      background-color: @primary-color;
      border-radius: 3px 0 0 3px;

      &--default {
        background-color: @primary-color;
      }

      &--success {
        background-color: @primary-color;
      }

      &--info {
        background-color: #39f;
      }

      &--warning {
        background-color: @orange-1;
      }

      &--error {
        background-color: @red-1;
      }
    }

    &__message {
      color: @gray-1;
      font-size: 14px;
      line-height: 20px;
      word-break: break-all;
    }
  }
}

@hack: true; @import "~@/assets/styles/color.less";