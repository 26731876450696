.drawer {
  :global {
    .ant-drawer-title {
      font-size: 16px !important;
    }

    .ant-drawer-wrapper-body {
      position: relative;
    }

    .ant-drawer-body{
      padding: 0
    }

    .ant-drawer-close {
      display: none;
    }
  }
}

.closeTouch {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.closeTouch i {
  color: @gray-3;
  font-weight: bold;
  font-size: 18px;

  &:hover,
  &:active {
    color: #666;
  }
}

@hack: true; @import "~@/assets/styles/color.less";