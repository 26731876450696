.content {
  flex: 1;
  padding: 20px;
  overflow: auto;

  & > :global(.ant-tabs) {
    position: relative;
    top: -20px;
    left: -20px;
    display: flex;
    flex-direction: column;
    width: calc(100% + 40px);
    height: 100%;
    overflow: hidden;

    & > :global(.ant-tabs-content) {
      flex: 1;

      & > :global(.ant-tabs-tabpane) {
        height: calc(100vh - 148px);
        padding: 20px !important;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: transparent;
          border-radius: 10px;
          transition: all 0.3s;
        }

        &::-webkit-scrollbar-track {
          border-radius: 10px;
        }

        &:hover {
          &::-webkit-scrollbar-thumb {
            background: rgba(0,0,0,10%);
          }
        }
      }
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  height: 80px;
  background-color: #fff;
}

@hack: true; @import "~@/assets/styles/color.less";