@import '~antd/dist/reset.css';
@import './assets/styles/rewrite.less';

body {
  min-width: 1200px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

ul,
li {
  list-style: none;
}

.ant-pro-basicLayout-children-content-wrap {
  display: flex;
  height: 100%;
}



.ka-wrapper {
  height: 100%;
}

.ka-content {
  height: 100%
}



// page样式
.basicLayout-content-container {
  overflow: hidden;
  background-color: #eff2f7;
  height: calc(100vh - 54px);
}

.basicLayout-content {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.disabledSelect {
  user-select: none;
}



// 返回按钮
.btn--goback {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 28px;
  margin-left: 20px;
  color: @gray-1;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  cursor: pointer;

  .fa {
    margin-right: 6px;
    font-size: 18px;
  }
}

.btn--goback:hover {
  color: rgb(77, 189, 115);
  border: 1px solid rgb(77, 189, 115);
}




#CONTAINER-CC-TOOLBAR-CUSTOM,
#CONTAINER-CC-TOOLBAR {
  top: 60px;
  right: 120px;
  z-index: 20;
  width: 270px;
}

#CONTAINER-CC-TOOLBAR[data-hidden='1'] {
  z-index: -1;
}

#CONTAINER-CC-TOOLBAR-CUSTOM {
  position: absolute;
  z-index: 22;
  height: 54px;
  line-height: 54px;
  text-align: center;
  background: #fff;
  border: 1px solid #e6e6e6;
  border-bottom: none;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  font-size: 15px;
  color: #666;
}

#root {
  height: 100%;
}

.centerLT() {
  display: flex;
  align-items: center;
  justify-content: center;
}


// 内容区域样式
//#root .ant-pro-basicLayout-children-content-wrap {
//  display: flex;
//  height: 100%;
//}

// // page样式
// #root .basicLayout-content-container {
//   position: absolute;
//   top: 4px;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   overflow: hidden;
//   background-color: #eff2f7;
// }

// #root .basicLayout-content {
//   width: 100%;
//   height: 100%;
//   padding: 20px;
// }

// // 隐藏collaps按钮
// #root .ant-pro-global-header-trigger {
//   display: none;
// }

// .ant-notification {
//   width: 320px;
//   margin-right: 40px;
// }

// .ant-input-disabled {
//   color: #bbbcbe !important;
//   //background-color: rgba(230, 230, 230, 1) !important;
//   background: #eee !important;
// }

// .ant-input-number-disabled {
//   color: #000 !important;
//   //background-color: rgba(230, 230, 230, 1) !important;
//   background: #fcfdfd !important;
// }

// .ant-select-disabled .ant-select-selection {
//   background: #fcfdfd !important;
// }

// textarea.ant-input {
//   &:focus {
//     box-shadow: none !important;
//   }
// }

// // pagination全局样式
// .ant-table-pagination {
//   width: 100%;
//   margin-top: 30px !important;
//   text-align: center;
// }

// .ant-pagination {
//   color: #999 !important;

//   .ant-pagination-item {
//     border-radius: 0;
//   }

//   .ant-pagination-item a {
//     color: #999;
//   }

//   .ant-pagination-prev a,
//   .ant-pagination-next a {
//     color: #999;
//   }

//   .ant-pagination-item-active {
//     background-color: @primary-color;
//   }

//   .ant-pagination-item-active a {
//     color: #fff;
//   }

//   .ant-pagination-item-active:focus a,
//   .ant-pagination-item-active:hover a {
//     color: #fff;
//   }

//   .ant-pagination-prev,
//   .ant-pagination-next,
//   .ant-pagination-jump-prev,
//   .ant-pagination-jump-next {
//     border-radius: 0;
//   }

//   .ant-pagination-prev .ant-pagination-item-link,
//   .ant-pagination-next .ant-pagination-item-link {
//     border-radius: 0;
//   }

//   .ant-select-selection {
//     color: #999;
//     border-radius: 0;
//   }

//   .ant-pagination-options-quick-jumper input {
//     border-radius: 0;
//   }
// }

// // 顶部logo样式
// .ant-pro-top-nav-header-logo {
//   width: auto !important;
// }

// .ant-pro-top-nav-header-left {
//   flex: 1;
// }

// .ant-pro-top-nav-header-menu {
//   flex: none !important;
// }

// // label 样式
// .ant-form-item-label > label {
//   width: 48px;
//   height: 22px;
//   color: #464c5b;
//   font-weight: 400;
//   font-size: 14px !important;
//   line-height: 22px;
// }

// // 去除label ：
// .ant-form-item > .ant-form-item-label > label::after {
//   content: '';
// }

// .ant-select-open .ant-select-selection {
//   box-shadow: none !important;
// }

// // 全局Button样式
// .ant-btn {
//   border-color: @gray-6;
//   border-radius: 3px;
//   box-shadow: 0 2px 2px rgba(0,0,0,47%) !important;

//   &-link {
//     border: none;
//     box-shadow: none !important;
//   }

//   &-lg {
//     padding: 0 30px;
//     font-size: 14px;
//   }

//   &-primary {
//     border: 1px solid #39a95f;
//   }

//   &-primary:hover {
//     border: 1px solid #39a95f;
//   }

//   &-primary:active,
//   &-primary:focus {
//     background-color: @primary-color;
//     border: 1px solid #39a95f;
//   }

//   &.ant-btn-dashed {
//     color: #fff;
//     background: @warn-color;
//     border: 1px solid #fc7c00;
//   }
// }

// .ant-btn.disabled,
// .ant-btn[disabled] {
//   color: @gray-4;
//   background-color: @gray-8;
//   border: 1px solid @gray-6;
// }

// .tableBtns {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 41px !important;

//   .ant-btn {
//     margin: 0 10px;
//   }
// }

// // 全局弹框样式
// .ant-message-notice-content {
//   height: 60px;
//   color: #fff;
//   font-size: 18px;
//   line-height: 40px;
//   background: rgba(0,0,0,70%);
//   border-radius: 2px;

//   .anticon {
//     margin-right: 24px;
//     font-size: 20px;
//   }
// }

// // 全局下拉框margin-bottom清除
// .ant-menu-vertical .ant-menu-item:not(:last-child) {
//   margin-bottom: 0 !important;
// }

// // 全局upload picture card
// .ant-upload-select-picture-card,
// .ant-upload-list-picture-card-container,
// .ant-upload-list-item-list-type-picture-card {
//   width: 120px !important;
//   height: 120px !important;
//   background-color: #fff !important;
// }

// .colorWeak {
//   filter: invert(80%);
// }

// .ant-layout {
//   min-height: 100vh;
// }

// .form-width {
//   width: 260px !important;
// }

// .form-width2 {
//   width: 240px !important;
// }

// canvas {
//   display: block;
// }

// .ant-pro-basicLayout {
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
// }

// ul,
// ol {
//   list-style: none;
// }

// // table 全局样式
// .ant-table-thead th {
//   height: 40px !important;
//   padding: 0 !important;
//   background-color: rgb(245,247,249);
// }

// .ant-table-thead > tr > th,
// .ant-table-tbody > tr > td {
//   padding: 14px 8px;
// }

// .ant-table-thead > tr:first-child > th:first-child {
//   border-radius: 0 !important;
// }

// .ant-table-thead > tr:first-child > th:last-child {
//   border-radius: 0 !important;
// }

// .custom-ant-table-thead {
//   td,
//   th {
//     padding: 14px 16px !important;
//   }

//   th {
//     height: 42px !important;
//     color: #1e2229 !important;
//     background-color: #f6f7fa !important;
//   }

//   .ant-table-tbody td {
//     color: #383c42;
//   }
// }

// @media (max-width: @screen-xs) {
//   .ant-table {
//     width: 100%;
//     overflow-x: auto;

//     &-thead > tr,
//     &-tbody > tr {
//       > th,
//       > td {
//         white-space: pre;

//         > span {
//           display: block;
//         }
//       }
//     }
//   }
// }

// .ant-popover {
//   .ant-popover-arrow {
//     border-color: rgb(241,248,255) !important;
//   }

//   .ant-popover-inner {
//     // background: rgba(241, 248, 255, 1);
//     box-shadow: 0 1px 3px 0 rgba(205,230,255,30%);
//   }
// }

// .ant-tabs-bar {
//   margin-bottom: 0 !important;
// }

// .table-btn-row {
//   padding: 0 !important;
// }

// // ------------------------------------------ switch -------------------------------------------
// .ant-switch {
//   width: 70px;
//   height: 30px !important;
//   background-color: @gray-3 !important;

//   &-checked {
//     background-color: @primary-color !important;
//   }

//   &-inner {
//     font-size: 14px !important;
//   }

//   &-loading-icon,
//   &::after {
//     top: 3px !important;
//     left: 3px !important;
//     width: 22px !important;
//     height: 22px !important;
//     border-radius: 22px !important;
//   }

//   &-checked::after {
//     left: 100% !important;
//     margin-left: -3px !important;
//   }
// }

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 10%);
  border-radius: 10px;
  transition: all 0.3s;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 40%);
}

.qiyu-close-btn {
  position: absolute;
  top: 20px;
  left: 238px;
  color: @gray-3;
  font-size: 20px;
  cursor: pointer;
}

// 滑窗上面打开弹窗时，滑窗的z-index值与弹窗的遮罩值一样为1000，出现遮罩不显示问题
.ant-modal-mask {
  z-index: 1001;
}

.gm-rct-health-desk {
  .gm-rtc-resizable-box {
    z-index: 1999;
  }
}

.gm-drawer-con {
  height: calc(100vh - 62px);
  overflow-x: hidden;
  overflow-y: auto;
}

.gm-drawer-fm-con {
  height: calc(100vh - 62px - 50px);
  overflow-x: hidden;
  overflow-y: auto;
}

.gm-drawer-fm-foot {
  display: flex;
  align-items: center;
  justify-content: right;
  height: 54px;
  padding-right: 26px;
  border-top: 1px solid #f0f3f8;
}

@hack: true; @import "~@/assets/styles/color.less";