.mask {
  background-color: rgba(0,0,0,60%) !important;
}

.photoProvider {
  :global {
    .PhotoView__Photo {
      width: auto !important;
      min-width: 750px;
      max-width: 900px;
      height: auto !important;
      min-height: 500px;
      max-height: 600px;
      object-fit: contain;
    }
  }
}

:global {
  .gm-image {
    cursor: zoom-in;
  }
}

@hack: true; @import "~@/assets/styles/color.less";