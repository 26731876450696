.addressCascader {
  width: 100%;
  height: 40px;

  :global {
    .ant-input {
      height: 40px;
      border-radius: 2px !important;
    }
  }
}

@hack: true; @import "~@/assets/styles/color.less";