.title {
  position: relative;
  line-height: 20px;
}

.title::before {
  position: absolute;
  top: 1px;
  left: -20px;
  width: 2px;
  height: 18px;
  background-color: @primary-color;
  content: '';
}

@hack: true; @import "~@/assets/styles/color.less";